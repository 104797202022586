import React from "react";
import { KV, useProvider, useReaction } from "use-reaction";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import "styles/home/index.less";
import "styles/home/preview.less";
import "./index.less";

import { admin_router_crowd_field_preview, admin_router_home, admin_router_sign_forget_pwd, admin_router_sign_in, admin_router_sign_in_2fa, admin_router_sign_reset_pwd } from "./routers";
import { AdminSignIn } from "./pages/admin_signin";
import { AdminHome } from "./pages/admin_home";
import EventPreview from "./pages/admin_activity/field_event/preview";
import GrantPreview from "@/pages/homepage/grants/Preview";
import { AdminSignIn2Fa } from "./pages/admin_signin_2fa";
import FieldCrowdPreview from '@/pages/dashboard/donation/Preview';
import ChangePwd from "@/pages/sign/password/ChangePwd";
import Recover from "@/pages/sign/password/Recover";

const AdminRouters = (props: KV) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={admin_router_sign_in} component={AdminSignIn} exact />
        <Route path={admin_router_sign_in_2fa} component={AdminSignIn2Fa} exact />
        <Route path={admin_router_sign_forget_pwd} component={(props: any)=><Recover admin {...props}/>} exact />
        <Route path={admin_router_sign_reset_pwd} component={ChangePwd} exact />
        <Route
          path="/event/:id/:edit(preview)?"
          component={EventPreview}
          exact
        />
        <Route path="/grant/:id" component={GrantPreview} exact />
        <Route path={admin_router_crowd_field_preview.replace('preview', ':preview(preview|view)')} component={FieldCrowdPreview} exact />
        <Route path={admin_router_home} component={AdminHome} strict />
      </Switch>
    </BrowserRouter>
  );
};

export const AdminApp: React.FC = () => {
  useReaction(process.env.NODE_ENV !== "production");

  const Provider = useProvider();
  return (
    <Provider>
      <AdminRouters />
    </Provider>
  );
};
